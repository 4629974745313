<template>
  <div class="lcr-fp-container">
    <div ref="pages" class="lcr-fp-container-inner">
      <div class="page">
        <img src="./img/page1-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page2-img1-a.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page2-img2.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page3-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page4-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page5-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page6-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page7-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page8-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page9-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page10-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page11-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page12-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page13-img1.png" alt="" />
      </div>
      <div class="page">
        <img src="./img/page14-img1.png" alt="" />
      </div>

      <div class="page">
        <div class="page16">
          <img src="./img/page16-top.png" alt="" class="top" />
          <img src="./img/page16-img1.png" alt="" class="img1" />
          <img src="./img/page16-img2.png" alt="" class="img2" />
          <img src="./img/page16-img3.png" alt="" class="img3" />
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import '@/lib/fullpage/zepto.fullpage.css'
import '@/lib/fullpage/js.fullpage.js'

export default {
  name: 'Full2017',
  mounted() {
    this.$refs.pages.fullpage()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.lcr-fp-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .lcr-fp-container-inner {
    @for $i from 1 through 16 {
      div:nth-child(#{$i}) {
        background: url(./img/page#{$i}-bg.jpg) no-repeat center;
      }
    }
    img {
      width: 90%;
      height: auto;
    }
    .page {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .page16 {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      img:nth-child(1) {
        position: absolute;
        width: 90%;
        height: auto;
        left: 5%;
        top: 5%;
      }
      img:nth-child(2) {
        position: absolute;
        width: 20%;
        height: auto;
        left: 12%;
        top: 60%;
      }
      img:nth-child(3) {
        position: absolute;
        width: 20%;
        height: auto;
        left: 50%;
        top: 60%;
        margin-left: -10%;
      }
      img:nth-child(4) {
        position: absolute;
        width: 20%;
        height: auto;
        right: 12%;
        top: 60%;
      }
    }
  }
}
</style>
